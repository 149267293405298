import React, { useRef } from 'react';
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import 'components/storeFrontEvents/style.scss';
import { Loader } from 'components/common/loader';
import { get } from 'lodash';
import Pagination from 'components/common/pagination';
import {
  IStoreFrontEvent,
  IStoreFrontEventResponse,
  IStoreFrontEventsFilter,
} from 'interface/storeInterface';
import globalStoreFrontService from 'services/storeFrontService';
import UpdateStoreFrontEvent from 'components/storeFrontEvents/updateStoreFrontEvent';
import SideFilter from 'components/storeFrontEvents/Filter/sideFilter';
import { useSearchParams } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';
import dayjs from 'dayjs';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formattedDate } from 'utils/helper';
import NestedTableComponent from 'components/storeFrontEvents/storeFrontNestedTable';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setCurrentFilter } from 'redux-setup/slices/pageConfigSlice';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IStoreFrontEventResponse) => ReactNode;
  hide?: boolean;
  key: string;
  showInSearch?: boolean;
  showSortIcon?: boolean;
}

export const defaultStoreFrontEventValues: IStoreFrontEventsFilter = {
  eventName: '',
  time: '',
  resolved: 'no',
  storeFilter: {
    StoreIDs: [],
  },
  tags: [],
  ipAddress: '',
  StartTime: null,
  EndTime: null,
};

export default function StoreFrontEvents() {
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPage, settotalPage] = useState<number>(0);
  const rowsPerPage = 25;
  const [storeFrontEvents, setStoreEvents] = useState<
    IStoreFrontEventResponse[]
  >([]);
  const [selectedEvent, setSelectedEvent] = useState<IStoreFrontEventResponse>({
    ChannelID: '',
    ChannelName: '',
    CreatedAt: '',
    Data: {},
    EventName: '',
    ID: '',
    Level: '',
    Name: '',
    Resolved: false,
    Store: {
      ID: '',
      Name: '',
    },
    Note: '',
    Tags: [],
    IPAddress: '',
    Version: 0,
  });
  const disPatch = useAppDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { storeIds } = useAppSelector(state => state.storeIds);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get('pageCount') || 1),
  );
  const [width, setWidth] = useState<number | undefined>(undefined);
  const ref = useRef<HTMLInputElement>(null);
  const [openRows, setOpenRows] = useState<boolean[]>();
  const [limit, setLimit] = useState<number>(
    searchParams.get('Limit')
      ? Number(searchParams.get('Limit')) || rowsPerPage
      : rowsPerPage,
  );
  const { timeZone } = useAppSelector(state => state.pathConfig);

  const [sideFormFilter, setSideFormFilter] = useState<IStoreFrontEventsFilter>(
    {
      time: searchParams.get('time')
        ? JSON.parse(searchParams.get('time') || '')
        : defaultStoreFrontEventValues.time,
      eventName: searchParams?.get('eventName')
        ? JSON.parse(searchParams.get('eventName') || '')
        : defaultStoreFrontEventValues.eventName,
      resolved: searchParams.get('resolved')
        ? JSON.parse(searchParams.get('resolved') || '')
        : defaultStoreFrontEventValues.resolved,
      storeFilter: {
        StoreIDs: searchParams.get('store_ids')
          ? JSON.parse(searchParams.get('store_ids') || '')
          : defaultStoreFrontEventValues.storeFilter.StoreIDs,
      },
      ipAddress: searchParams.get('ipAddress')
        ? JSON.parse(searchParams.get('ipAddress') || '')
        : defaultStoreFrontEventValues.ipAddress,
      tags: searchParams.get('tags')
        ? JSON.parse(searchParams.get('tags') || '')
        : defaultStoreFrontEventValues.tags,
      StartTime: searchParams.get('StartTime')
        ? dayjs(searchParams.get('StartTime')).tz()
        : defaultStoreFrontEventValues.StartTime,
      EndTime: searchParams.get('EndTime')
        ? dayjs(searchParams.get('EndTime')).tz()
        : defaultStoreFrontEventValues.EndTime,
    },
  );
  const headCellList: readonly HeadCell[] = [
    {
      id: 'CreatedDate',
      label: 'Time',
      key: 'date',
      hide: false,
      showSortIcon: true,
      cellRender: row => {
        return (
          <Tooltip placement="top" title={formattedDate(row?.CreatedAt)} arrow>
            <span>{formattedDate(row?.CreatedAt, true)}</span>
          </Tooltip>
        );
      },
      showInSearch: true,
    },
    {
      id: 'Store.Name',
      label: 'Store',
      key: 'store',
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'Name',
      label: 'ChannelName',
      key: 'name',
      hide: false,
      cellRender: row => {
        return row.ChannelName;
      },
      showSortIcon: false,
      showInSearch: true,
    },
    {
      id: 'EventName',
      label: 'Event Name',
      key: 'event_name',
      hide: false,
      showSortIcon: true,
      cellRender: row => {
        return row.EventName;
      },
    },
    {
      id: 'Level',
      label: 'Level',
      key: 'level',
      hide: false,
      showSortIcon: true,
      showInSearch: true,
      cellRender: row => {
        return row.Level;
      },
    },
    {
      id: 'Resolved',
      label: 'Resolved',
      key: 'resolved',
      hide: false,
      cellRender: row => {
        return row.Resolved == true ? 'Yes' : 'No';
      },
      showSortIcon: false,
    },
    {
      id: 'Tags',
      label: 'Tags',
      key: 'tags',
      hide: false,
      cellRender: row => {
        return row.Tags;
      },
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'IPAddress',
      label: 'IP Address',
      key: 'ipAddress',
      hide: false,
      cellRender: row => {
        return row.IPAddress;
      },
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'Notes',
      label: 'Notes',
      key: 'notes',
      hide: false,
      cellRender: row => {
        return row.Note;
      },
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'Actions',
      label: 'Actions',
      key: 'actions',
      hide: false,
      cellRender: row => {
        return (
          <CreateIcon
            onClick={() => {
              setIsEdit(val => !val);
              setSelectedEvent(row);
            }}
          />
        );
      },
      showSortIcon: true,
      showInSearch: true,
    },
  ];

  const searchStoreFrontEvents = async () => {
    setOpenRows([]);
    const payload: IStoreFrontEvent = {
      Descending: searchParams.get('descending') === 'false' ? false : true,
      OrderBy: searchParams.get('orderBy')
        ? JSON.parse(searchParams.get('orderBy') || '')
        : 'CreatedAt',
      Limit: limit,
      Page: page - 1,
    };
    if (storeIds?.length > 0) {
      payload.StoreIDs = storeIds.map(store => store.ID);
    }

    if (sideFormFilter.tags.length > 0) {
      payload.Tags = sideFormFilter.tags;
    }

    if (sideFormFilter.eventName) {
      payload.EventName = sideFormFilter.eventName;
    }
    if (sideFormFilter.ipAddress) {
      payload.IPAddress = sideFormFilter.ipAddress;
    }
    if (sideFormFilter.resolved) {
      if (sideFormFilter.resolved === 'all') {
        payload.Resolved = null;
      } else payload.Resolved = sideFormFilter.resolved === 'yes';
    }
    if (sideFormFilter?.StartTime) {
      payload.StartTime = dayjs(sideFormFilter?.StartTime)
        .tz()
        .startOf('day')
        .format();
    }

    if (sideFormFilter?.EndTime) {
      payload.EndTime = dayjs(sideFormFilter?.EndTime)
        .tz()
        .add(1, 'day')
        .startOf('day')
        .format();
    }

    setSearchParams(
      {
        time: JSON.stringify(sideFormFilter.time),
        eventName: JSON.stringify(sideFormFilter.eventName),
        resolved: JSON.stringify(sideFormFilter.resolved),
        tags: JSON.stringify(sideFormFilter.tags),
        store_ids: JSON.stringify(sideFormFilter.storeFilter.StoreIDs),
        ipAddress: JSON.stringify(sideFormFilter.ipAddress),
        descending: JSON.stringify(payload.Descending),
        orderBy: JSON.stringify(payload.OrderBy),
        pageCount: JSON.stringify(page),
        StartTime: sideFormFilter.StartTime
          ? sideFormFilter.StartTime.tz().format()
          : '',
        EndTime: sideFormFilter.EndTime
          ? sideFormFilter.EndTime.tz().format()
          : '',
        Limit: String(limit),
      },
      { replace: true },
    );
    setLoading(true);
    const res = await globalStoreFrontService.getStoreFrontEvents(payload);
    setLoading(false);
    if (res?.status === 200) {
      if (res?.data?.Result?.length > 0) {
        setStoreEvents(res?.data?.Result);
        settotalPage(res?.data?.TotalCount);
      } else {
        setStoreEvents([]);
        settotalPage(0);
      }
    } else {
      setStoreEvents([]);
      settotalPage(0);
    }
  };

  const handleRowToggle = (index: number) => {
    const newOpenRows = [...(openRows ?? [])];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  useEffect(() => {
    searchStoreFrontEvents();
    const updateWidth = () => {
      if (ref.current) {
        const newWidth = ref.current.offsetWidth;
        setWidth(newWidth);
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
      disPatch(setCurrentFilter(''));
    };
  }, [sideFormFilter, page, limit, storeIds, timeZone]);

  return (
    <>
      <div className="store_front_events">
        <div className="shopify_wrapper">
          <div className="store_management">
            <TableContainer className="Common_Table">
              <Table
                className="risk_management_table"
                aria-labelledby="tableTitle"
                stickyHeader>
                <TableHead className="table_header">
                  <TableRow>
                    <TableCell className="resolved_header" />
                    <TableCell className="table_header_cell" />
                    {headCellList.map(headCell => {
                      if (headCell.hide) {
                        return null;
                      }
                      return (
                        <TableCell
                          className="table_header_cell header_text"
                          key={headCell.label}>
                          {headCell.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className="table_body">
                  {storeFrontEvents?.length > 0 ? (
                    storeFrontEvents.map((row, index) => {
                      return (
                        <>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}>
                            <TableCell
                              className={`resolved_row ${row?.Resolved ? 'resolved' : 'not_resolved'}`}
                            />
                            <TableCell className="table_cell">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                className="expand-row"
                                onClick={() => handleRowToggle(index)}>
                                {(openRows ? openRows[index] : false) ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowRightIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            {headCellList.map(headCell => {
                              if (headCell.hide) {
                                return null;
                              }
                              return (
                                <TableCell
                                  className="table_cell"
                                  key={headCell.label}
                                  component="th"
                                  id={headCell.id}
                                  scope="row">
                                  <>
                                    {headCell?.cellRender
                                      ? headCell.cellRender(row)
                                      : get(row, headCell.id)}
                                  </>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          {(openRows ? openRows[index] : false) && (
                            <TableRow className="row_collapsed">
                              <TableCell
                                className="table_border_none nested-table-cell"
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={15}>
                                <Collapse
                                  in={openRows ? openRows[index] : false}
                                  timeout="auto"
                                  unmountOnExit>
                                  <div
                                    style={{ width: width ? width : 'auto' }}>
                                    <NestedTableComponent
                                      row={row.Data ? row.Data : null}
                                    />
                                  </div>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <div className="no-data-row">No data found</div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              totalData={totalPage}
              limit={limit}
              onRowsPerChange={val => {
                setLimit(val);
              }}
              perPage={limit}
              currentPage={page}
              onHandleChange={page => {
                setPage(page);
              }}
            />
          </div>
          <Loader loading={loading} />
        </div>
        {isEdit && (
          <UpdateStoreFrontEvent
            handleClose={() => setIsEdit(false)}
            payload={selectedEvent}
            onSuccess={searchStoreFrontEvents}
          />
        )}
        <SideFilter
          sideFilter={sideFormFilter}
          setPage={setPage}
          filterSubmission={data => {
            setSideFormFilter(data);
          }}
        />
      </div>
    </>
  );
}
