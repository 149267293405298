import { FC, useEffect, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { Checkbox } from 'shared-components';
import TextField from 'components/common/textField';
import {
  IRefundPayload,
  IRefundTransaction,
} from 'interface/transactionInterface';
import { Controller, useForm } from 'react-hook-form';
import globalTransactionService from 'services/transactionService';
import { showNotification } from 'helper/common/commonFunctions';
import {
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
} from '@mui/material';
import orderService from 'services/orderService';
import { IUsedOrderDetails } from 'interface/orderDetailInterface';
import { Loader } from 'components/common/loader';
import ToggleSwitch from 'components/common/toggleSwitch';
import './style.scss';

const availableActions = {
  refund: 'Refund',
};

interface IRefundDetails {
  refundValue: string;
  cancelSubscription: boolean;
}

interface Iprops {
  payload: IRefundPayload;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  onApiResponse: (_: boolean) => void;
  handleClose: () => void;
}

const NewRefundTransaction: FC<Iprops> = ({
  payload,
  setLoading,
  onApiResponse,
  handleClose,
}) => {
  const initialOrderDetails: IUsedOrderDetails = {
    Items: [],
  };
  const [isRefundFullOrder, setRefundFullOrder] = useState(true);
  const [orderRecords, setOrderRecords] =
    useState<IUsedOrderDetails>(initialOrderDetails);
  const [changedOrderRecords, setChangedOrderRecords] =
    useState<IUsedOrderDetails>(initialOrderDetails);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [totalRefundAmount, setTotalRefundAmount] = useState('0');
  const [refundNotes, setRefundNotes] = useState('');
  const [actionLoading, setActionLoading] = useState(false);
  const [lineItemsLoading, setlineItemsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    trigger,
  } = useForm<IRefundDetails>({
    defaultValues: {
      refundValue: Number('0.00').toFixed(2),
      cancelSubscription: false,
    },
  });

  const isAllSelected =
    selectedItems.length === changedOrderRecords?.Items?.length;

  const getOrderDetails = async (payload: IRefundPayload) => {
    if (payload?.TransactionKind === 'subscription') return;
    setlineItemsLoading(true);
    if (!payload.StoreID || !payload.OrderID) return;
    const orderDetails = await orderService.getOrderById(
      payload.StoreID,
      payload.OrderID,
    );
    if (orderDetails?.data?.Order) {
      setChangedOrderRecords(orderDetails.data.Order);
      setOrderRecords(orderDetails.data.Order);
    }
    setlineItemsLoading(false);
  };

  const handleSelectAllClick = () => {
    let unitPrice = 0;
    if (isAllSelected) {
      setSelectedItems([]);
      setTotalRefundAmount('0.00');
    } else {
      const allIndices = changedOrderRecords?.Items?.map((item, index) => {
        unitPrice += Number(item.UnitPrice) * item.Quantity;
        return index;
      });
      setSelectedItems(allIndices);
      setTotalRefundAmount(calculateTotalRefund(allIndices));
    }
    setValue('refundValue', unitPrice.toFixed(2));
  };

  const calculateTotalRefund = (selectedIndices: number[]) => {
    const totalRefund = selectedIndices.reduce((acc, index) => {
      const item = changedOrderRecords?.Items[index];
      return acc + parseFloat(item?.UnitPrice) * item?.Quantity;
    }, 0);

    return totalRefund.toFixed(2);
  };

  const handleSelectItemClick = (index: number) => {
    setSelectedItems(prevSelected => {
      const isSelected = prevSelected.includes(index);

      // Recalculate the refund value based on the updated selection
      const updatedSelectedItems = isSelected
        ? prevSelected.filter(i => i !== index)
        : [...prevSelected, index];

      // Calculate the updated refund value
      const updatedRefundValue = updatedSelectedItems.reduce(
        (total, selectedIndex) => {
          const selectedItem = changedOrderRecords?.Items[selectedIndex];
          return (
            total +
            Number(selectedItem?.UnitPrice) * Number(selectedItem?.Quantity)
          );
        },
        0,
      );

      setValue('refundValue', updatedRefundValue.toFixed(2));
      setTotalRefundAmount(updatedRefundValue.toFixed(2));

      return updatedSelectedItems;
    });
  };

  const getLineItems = (): { Quantity: number; VariantID: number }[] => {
    return selectedItems?.map(index => {
      const item = changedOrderRecords?.Items[index];
      const variantID = item?.VariantID?.split('/').pop(); // Extract the number part of the VariantID
      return {
        Quantity: item?.Quantity ? item?.Quantity : 0,
        VariantID: variantID ? parseInt(variantID) : 0, // Convert it to a number
      };
    });
  };

  const handleQuantityChange = (index: number, newQuantity: number) => {
    setChangedOrderRecords(prevRecords => {
      const updatedItems = prevRecords?.Items?.map((item, i) =>
        i === index ? { ...item, Quantity: newQuantity } : item,
      );

      // Recalculate the refundValue based on all selected items
      const updatedRefundValue = selectedItems.reduce(
        (total, selectedIndex) => {
          const selectedItem = updatedItems[selectedIndex];
          return (
            total +
            Number(selectedItem?.UnitPrice) * Number(selectedItem?.Quantity)
          );
        },
        0,
      );

      setValue('refundValue', updatedRefundValue.toFixed(2));

      return { ...prevRecords, Items: updatedItems };
    });
  };

  const refundTransaction = async (data: IRefundDetails) => {
    if (!payload) return;
    if (
      payload?.TransactionKind !== 'subscription' &&
      selectedItems?.length === 0 &&
      !isRefundFullOrder
    )
      return showNotification(
        'error',
        'Please select at least one item to refund',
      );
    setActionLoading(true);
    setLoading && setLoading(true);
    payload.Amount = data.refundValue;
    const refundPayload: IRefundTransaction = {
      Amount: payload?.Amount,
      CancelSubscription: data?.cancelSubscription,
      CustomerID: payload?.CustomerID,
      StoreID: payload?.StoreID,
      TransactionID: payload?.TransactionID,
      ChannelID: payload?.ChannelID,
    };
    if (payload?.ExternalOrderID) {
      refundPayload.OrderID = parseInt(payload?.ExternalOrderID);
    }
    if (payload?.TransactionKind !== 'subscription') {
      refundPayload.OrderOptions = {
        FullOrder: isRefundFullOrder,
        Note: refundNotes ? refundNotes : '',
        RefundLineItems: isRefundFullOrder ? [] : getLineItems(),
        TotalAmount: isRefundFullOrder ? '' : totalRefundAmount,
      };
    }
    setLoading && handleClose();
    const res = await globalTransactionService.refundTransaction(refundPayload);
    if (res.status === 200) {
      onApiResponse(true);
      showNotification('success', 'Transaction Refund Success');
    } else {
      onApiResponse(false);
    }
    handleClose();
    setLoading && setLoading(false);
    setActionLoading(false);
  };

  const setRefundValues = () => {
    if (isRefundFullOrder) {
      setValue('refundValue', Number(payload?.Amount).toFixed(2));
    } else {
      setValue('refundValue', Number('0.00').toFixed(2));
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    setRefundValues();
  }, [isRefundFullOrder]);

  useEffect(() => {
    setTotalRefundAmount(calculateTotalRefund(selectedItems));
  }, [changedOrderRecords]);

  useEffect(() => {
    if (payload?.StoreID && payload?.OrderID) {
      getOrderDetails(payload);
    }
  }, []);

  return (
    <PopUpModal
      open={true}
      handleClose={() => {
        handleClose();
      }}
      buttons={[
        {
          buttonType: 'positive',
          buttonLabel: 'Confirm',
          form: 'order-detail_refund',
          type: 'submit',
          disabled: actionLoading,
          loading: actionLoading,
        },
      ]}
      className="order-detail_refund_body gap-3 flex-col !overflow-visible h-full min-h-max !items-start"
      heading={availableActions.refund}>
      <div className="flex items-center">
        <p className="text-white">Refund Full Order</p>
        <ToggleSwitch
          checked={isRefundFullOrder}
          onChange={() => {
            setRefundFullOrder(!isRefundFullOrder);
            setRefundValues();
            if (isRefundFullOrder) clearErrors('refundValue');
            else trigger('refundValue');
          }}
        />
      </div>
      {!isRefundFullOrder && payload?.TransactionKind !== 'subscription' && (
        <>
          <TableContainer className="Common_Table !h-fit max-h-32">
            <Table>
              <TableHead className="refund_th">
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={isAllSelected}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Price / Qty</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {changedOrderRecords?.Items?.length > 0 ? (
                  changedOrderRecords?.Items?.map((row, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      selected={selectedItems.includes(index)}>
                      <TableCell>
                        <Checkbox
                          checked={selectedItems.includes(index)}
                          onChange={() => handleSelectItemClick(index)}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          type="number"
                          value={row.Quantity}
                          className="w-16 p-1 border rounded text-black text-center"
                          min={1}
                          max={orderRecords?.Items[index]?.Quantity}
                          onChange={e =>
                            handleQuantityChange(
                              index,
                              Math.max(1, parseInt(e.target.value) || 1),
                            )
                          }
                        />
                      </TableCell>
                      <TableCell className="product-name">{row.Name}</TableCell>
                      <TableCell>
                        <img
                          src={row.ImageURL}
                          alt={row.Name}
                          style={{
                            width: '40px',
                            height: '40px',
                            objectFit: 'cover',
                          }}
                        />
                      </TableCell>
                      <TableCell>${row.UnitPrice}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="!h-20">
                      <div className="no-data-row">No data found</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Loader loading={lineItemsLoading} />
      <form
        id="order-detail_refund"
        onSubmit={handleSubmit(refundTransaction)}
        className="order-detail_refund flex-col !max-w-full">
        <div className="direct_amount_field flex-col">
          <div className="flex items-end w-full">
            <Controller
              name="refundValue"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    label="Refund Amount *"
                    placeholder="Enter Amount"
                    value={value}
                    fixedSize="md"
                    onChange={e => {
                      onChange(
                        e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*?)\..*/g, '$1'),
                      );
                    }}
                    disabled={isRefundFullOrder}
                    errorString={error?.message}
                    className={
                      isRefundFullOrder
                        ? 'disabled refund-field'
                        : 'refund-field'
                    }
                  />
                );
              }}
              rules={{
                required: !isRefundFullOrder
                  ? 'Refund Amount is required'
                  : false,
                validate: (value: string) => {
                  const totalSelectedItemsRefund =
                    parseFloat(totalRefundAmount);
                  if (isRefundFullOrder) {
                    return true; // Skip validation when refunding the full order
                  }
                  if (isNaN(Number(value))) {
                    return 'Refund Amount should be a number';
                  }
                  if (Number(value) > Number(payload?.Amount)) {
                    return `Only $${Number(payload?.Amount).toFixed(2)} available for Refund`;
                  }
                  if (Number(value) > totalSelectedItemsRefund) {
                    return `Refund amount exceeds the selected items total of $${totalSelectedItemsRefund.toFixed(2)}`;
                  }
                  return true;
                },
              }}
            />
            <p
              className={`direct_amount_text ${errors.refundValue?.message ? 'additional_margin' : ''}`}>
              <span>/</span> ${Number(payload?.Amount).toFixed(2)}
            </p>
          </div>
          <TextField
            placeholder="Enter Notes"
            label="Refund Notes"
            fixedSize="md"
            onChange={e => setRefundNotes(e.target.value)}
          />
        </div>
      </form>
    </PopUpModal>
  );
};

export default NewRefundTransaction;
