import { useEffect, useMemo, useState } from 'react';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import CustomDatePicker from 'components/common/customDatePicker';
import 'components/dashboard/style.scss';
import {
  getSubscriptionStats,
  getTransactionStats,
  getUniqueTransactionStats,
} from 'services/dashboardService';
import {
  IFilter,
  ISubscriptionStats,
  ISubscriptionStatsBody,
  ITransactionStats,
  IUniqueTransactionStats,
} from 'interface/dashboardInterface';
import { emptyStats, groupPoints } from 'utils/helper';
import SalesRevenue from 'components/dashboard/SalesRevenue';
import CoverageHealth from 'components/dashboard/CoverageHealth';
import CardBreakdown from 'components/dashboard/CardBreakdown';
import ChargeBackSummary from 'components/dashboard/ChargeBackSummary';
import ApprovalRatio from 'components/dashboard/ApprovalRatio';
import NetSubscribers from 'components/dashboard/NetSubscribers';
import SubscriptionRefundRatio from 'components/dashboard/SubscriptionRefundRatio';
import StraightSaleRefundRatio from 'components/dashboard/StraightSaleRefundRatio';
import StatsCards from 'components/dashboard/StatsCards';
import { useSearchParams } from 'react-router-dom';
import { Loader } from 'components/common/loader';
import { useAppSelector } from 'hooks/reduxHooks';

dayjs.extend(utc);

const Dashboard = () => {
  const default_start_time = dayjs().tz().startOf('day');
  const default_end_time = dayjs().tz().endOf('day');
  const [loading, setLoading] = useState(false);
  const [subscriptionStats, setSubscriptionStats] =
    useState<ISubscriptionStats | null>(null);
  const storeIds = useAppSelector(state => state.storeIds.storeIds);
  const [searchParams, setSearchParams] = useSearchParams();
  const [uniqueTransactionStats, setUniqueTransactionStats] =
    useState<IUniqueTransactionStats | null>(null);
  const [transactionStats, setTransactionStats] =
    useState<ITransactionStats | null>(null);
  const { timeZone } = useAppSelector(state => state.pathConfig);
  const [filter, setFilter] = useState<IFilter>({
    store_ids: searchParams.get('store_ids')
      ? JSON.parse(searchParams.get('store_ids') || '')
      : [],
    start_time: searchParams.get('start_time')
      ? dayjs(searchParams.get('start_time')).tz()
      : default_start_time,
    end_time: searchParams.get('end_time')
      ? dayjs(searchParams.get('end_time')).tz()
      : default_end_time,
    date_range: searchParams.get('date_range') || 'Today',
  }); // setting default start and end time
  const groupedTransactionStats = useMemo(() => {
    if (!transactionStats || !transactionStats?.Stats?.length) {
      return [];
    }
    const grouped = groupBy(transactionStats.Stats, 'Date');
    return groupPoints(grouped, emptyStats.transaction);
  }, [transactionStats]);

  const groupedUniqueTransactionStats = useMemo(() => {
    if (!uniqueTransactionStats || !uniqueTransactionStats?.Stats?.length) {
      return [];
    }
    const grouped = groupBy(uniqueTransactionStats.Stats, 'Date');
    return groupPoints(grouped, emptyStats.uniqueTransaction);
  }, [uniqueTransactionStats]);

  const groupedSubscriptionStats = useMemo(() => {
    if (!subscriptionStats || !subscriptionStats?.Stats?.length) {
      return [];
    }
    const grouped = groupBy(subscriptionStats.Stats, 'Date');
    return groupPoints(grouped, emptyStats.subscription);
  }, [subscriptionStats]);

  const handleFilter = (
    filterProptery: keyof typeof filter,
    value: unknown,
  ) => {
    setFilter(pre => ({ ...pre, [filterProptery]: value }));
  };

  const getDashboardstats = async () => {
    const payload: ISubscriptionStatsBody = {};
    if (storeIds.length > 0) {
      payload.StoreIDs = storeIds.map(store => store.ID);
    }
    if (filter.start_time) {
      payload.StartTime = filter.start_time.tz().format();
    }
    if (filter.end_time) {
      payload.EndTime = filter.end_time
        .add(1, 'day')
        .startOf('day')
        .tz()
        .format();
    }
    setSearchParams(
      {
        store_ids: filter.store_ids ? JSON.stringify(filter.store_ids) : [],
        start_time: dayjs(filter.start_time).tz().format() || '',
        end_time: dayjs(filter.end_time).tz().format() || '',
        date_range: filter.date_range,
      },
      { replace: true },
    );
    setLoading(true);
    const response = await Promise.allSettled([
      getSubscriptionStats(payload),
      getTransactionStats(payload),
      getUniqueTransactionStats(payload),
    ]);

    if (response[0].status === 'fulfilled' && response[0]?.value?.data) {
      setSubscriptionStats(response[0].value.data);
    } else setSubscriptionStats(null);
    if (response[1].status === 'fulfilled' && response[1]?.value?.data) {
      setTransactionStats(response[1].value.data);
    } else setTransactionStats(null);
    if (response[2].status === 'fulfilled' && response[2]?.value?.data) {
      setUniqueTransactionStats(response[2]?.value?.data);
    } else setUniqueTransactionStats(null);
    setLoading(false);
  };

  useEffect(() => {
    getDashboardstats();
  }, [filter, storeIds, timeZone]);

  return (
    <div className="dashboard_wrapper">
      <div className="dashboard_grid">
        <div className="dashboard_filter row-full">
          {/* Dashboard filters will be updated only on field Blur */}
          <div className="dual-date-container">
            <div className="common_label_text">Date Range</div>
            <CustomDatePicker
              selectedRange={{
                startDate: filter.start_time,
                endDate: filter.end_time,
              }}
              dateValues={range => {
                if (range.startDate && range.endDate) {
                  handleFilter('start_time', range.startDate);
                  handleFilter('end_time', range.endDate);
                }
              }}
              maxDate={dayjs().tz().toDate()}
            />
          </div>
        </div>
        <StatsCards
          uniqueTransactionStats={uniqueTransactionStats}
          transactionStats={transactionStats}
          subscriptionStats={subscriptionStats}
          filter={filter}
        />
        <SalesRevenue groupedTransactionStats={groupedTransactionStats} />
        <NetSubscribers groupedSubscriptionStats={groupedSubscriptionStats} />
        <ApprovalRatio
          groupedTransactionStats={groupedUniqueTransactionStats}
        />
        <CardBreakdown groupedTransactionStats={groupedTransactionStats} />
        <ChargeBackSummary groupedTransactionStats={groupedTransactionStats} />
        <CoverageHealth groupedTransactionStats={groupedTransactionStats} />
        <SubscriptionRefundRatio
          groupedTransactionStats={groupedTransactionStats}
        />
        <StraightSaleRefundRatio
          groupedTransactionStats={groupedTransactionStats}
        />
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default Dashboard;
