import React, { ReactNode, useEffect, useState } from 'react';
import 'components/risk/style.scss';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import {
  IRiskFormFilter,
  ISearchRiskBody,
  IRiskManagement,
  IReportsDisputeDetails,
} from 'interface/riskInterface';
import LineChart from 'components/common/chart/lineChart';
import { ScriptableContext } from 'chart.js';
import SideFilter from 'components/risk/riskFilter/sideFilter';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setCurrentFilter } from 'redux-setup/slices/pageConfigSlice';
import DisputeOverTime from 'components/risk/disputeOverTime';
import dayjs from 'dayjs';
import globalRiskService from 'services/riskService';
import { Loader } from 'components/common/loader';
import { get, groupBy } from 'lodash';
import { groupPoints, sortByDateAscending } from 'utils/helper';
import DisputeReasonCode from 'components/risk/disputeReasonCode';
import { allDateRange } from 'components/dashboard/constant';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IRiskManagement) => ReactNode;
  hide?: boolean;
}
export interface CardList {
  title: string;
  amount: number;
  percentage?: () => string;
  cardData: number[];
}

export const defaultOrderFilterValues: IRiskFormFilter = {
  dateRange: allDateRange.allTime,
  startTime: null,
  endTime: null,
  isAllCycles: false,
  minCycles: null,
  maxCycles: null,
  transactionDate: '',
  cardBrand: '',
  transactiontype: '',
  dateFilterBy: 'Acquired',
};

const headCellList: readonly HeadCell[] = [
  {
    id: 'CycleName',
    label: 'Cycle Name',
    hide: false,
    cellRender: row => {
      if (row.Cycle === 0) {
        return 'Direct Sale';
      }
      return 'Cycle' + row.Cycle;
    },
  },
  {
    id: 'TotalTransactions',
    label: 'Total Approved',
    hide: false,
    cellRender: row => {
      return row.Total;
    },
  },
  {
    id: 'TotalDisputes',
    label: 'Total Disputes',
    hide: false,
    cellRender: row => {
      return row.CDRN + row.Ethoca + row.RDR;
    },
  },
  {
    id: 'DisputeRatio',
    label: 'Dispute Ratio',
    hide: false,
    cellRender: row => {
      return (
        Number(
          ((row.CDRN + row.Ethoca + row.RDR + row.Chargebacks) / row.Total) *
            100,
        ).toFixed(2) + '%'
      );
    },
  },
  {
    id: 'Chargebacks',
    label: 'Total Chargebacks',
    hide: false,
    cellRender: row => row.Chargebacks,
  },
  {
    id: 'ChargebackRatio',
    label: 'Chargeback Ratio',
    hide: false,
    cellRender: row => {
      return Number((row.Chargebacks / row.Total) * 100).toFixed(2) + '%';
    },
  },
  {
    id: 'VisaRdr',
    label: 'VISA RDR',
    hide: false,
    cellRender: row => {
      return row.RDR;
    },
  },
  {
    id: 'VisaCdrn',
    label: 'VISA CDRN',
    hide: false,
    cellRender: row => {
      return row.CDRN;
    },
  },
  {
    id: 'Ethoca',
    label: 'ETHOCA',
    hide: false,
    cellRender: row => {
      return row.Ethoca;
    },
  },
];

function index() {
  const [loading, setLoading] = useState<boolean>(false);
  const [riskOfChart, setRiskOfChart] = useState<[string, IRiskManagement[]][]>(
    [],
  );
  const storeDetails = useAppSelector(state => state.storeIds.storeIds);
  const { timeZone } = useAppSelector(state => state.pathConfig);

  const [riskTable, setRiskTable] = useState<IRiskManagement[]>([]);
  const [chartCardLabel, setChartCardLabel] = useState<string[]>([]);
  const [disputeCodesData, setDisputeCodesData] = useState<
    IReportsDisputeDetails[]
  >([]);
  const [topGraph, setTopGraph] = useState<CardList[]>([]);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sideFormFilter, setSideFormFilter] = useState<IRiskFormFilter>(() => {
    const filterDetailsString = searchParams.get('filter-details');
    const filterDetails: IRiskFormFilter = {
      dateRange: defaultOrderFilterValues.dateRange,
      startTime: defaultOrderFilterValues.startTime,
      endTime: defaultOrderFilterValues.endTime,
      isAllCycles: defaultOrderFilterValues.isAllCycles,
      minCycles: defaultOrderFilterValues.minCycles,
      maxCycles: defaultOrderFilterValues.maxCycles,
      transactionDate: defaultOrderFilterValues.transactionDate,
      cardBrand: defaultOrderFilterValues.cardBrand,
      transactiontype: defaultOrderFilterValues.transactiontype,
      dateFilterBy: defaultOrderFilterValues.dateFilterBy,
    };
    if (typeof filterDetailsString === 'string') {
      const {
        dateFilterBy,
        startTime,
        endTime,
        cardBrand,
        transactionDate,
        transactiontype,
      } = JSON.parse(filterDetailsString);
      startTime && (filterDetails.startTime = dayjs(startTime).tz());
      endTime && (filterDetails.endTime = dayjs(endTime).tz());
      cardBrand && (filterDetails.cardBrand = cardBrand);
      transactiontype && (filterDetails.transactiontype = transactiontype);
      transactionDate && (filterDetails.transactionDate = transactionDate);
      dateFilterBy && (filterDetails.dateFilterBy = dateFilterBy);
    }
    return filterDetails;
  });
  useEffect(() => {
    return () => {
      dispatch(setCurrentFilter(''));
    };
  }, []);

  useEffect(() => {
    searchRiskList();
  }, [sideFormFilter, storeDetails, timeZone]);

  const groupedRisk = (riskList: IRiskManagement[]) => {
    if (!riskList || !riskList?.length) {
      return [];
    }
    const grouped = groupBy(riskList, 'Date');
    setRiskOfChart(groupPoints(grouped));
  };

  const filterSubmission = (formData: IRiskFormFilter) => {
    setSideFormFilter(formData);
  };

  const searchRiskList = async () => {
    setLoading(true);
    const { endTime, startTime, dateFilterBy, cardBrand } = sideFormFilter;
    const storesIds = storeDetails ? storeDetails.map(store => store?.ID) : [];
    const payload: ISearchRiskBody = {};
    if (dateFilterBy === 'Acquired') {
      if (startTime) {
        payload.AcquisitionStartTime = startTime.tz().startOf('day').format();
      }
      if (endTime) {
        payload.AcquisitionEndTime = endTime
          .tz()
          .add(1, 'day')
          .startOf('day')
          .format();
      }
    } else {
      if (startTime) {
        payload.StartTime = startTime.tz().startOf('day').format();
      }
      if (endTime) {
        payload.EndTime = endTime.tz().add(1, 'day').startOf('day').format();
      }
    }
    if (storesIds.length > 0) {
      payload.StoreIDs = storesIds;
    }

    const disputeCodePayload = {
      ...payload,
      ...(cardBrand ? { CardBrand: cardBrand } : {}),
    };

    setSearchParams(
      {
        'filter-details': JSON.stringify(sideFormFilter),
      },
      { replace: true },
    );
    const [response, disputeCodeResponse] = await Promise.all([
      globalRiskService.searchRisk(payload),
      globalRiskService.getDisputeCodes(disputeCodePayload),
    ]);

    if (response?.status === 200) {
      if (response?.data?.Result?.length > 0) {
        getRiskDatas(response?.data?.Result);
        groupedRisk(response?.data?.Result);
      } else {
        setRiskOfChart([]);
        getRiskDatas([]);
      }
    } else {
      setRiskOfChart([]);
      getRiskDatas([]);
    }

    if (disputeCodeResponse?.status === 200) {
      if (disputeCodeResponse?.data?.Result?.length > 0) {
        setDisputeCodesData(disputeCodeResponse?.data?.Result);
      } else {
        setDisputeCodesData([]);
      }
    } else {
      setDisputeCodesData([]);
    }
    setLoading(false);
  };

  const getRiskDatas = (data: IRiskManagement[]) => {
    const sortedData = sortByDateAscending(data, 'Date', 'ASC');
    const groupedData = groupBy(sortedData, 'Date');
    const dateLabel: string[] = [];
    const groupedPoints = Object.keys(groupedData)?.length
      ? groupPoints(groupedData)
      : [];
    const chartData = groupedPoints?.map(([date, transactions]) => {
      dateLabel.push(date);
      return transactions.reduce(
        (acc, transaction) => {
          acc.Total += transaction.Total;
          acc.Cycle += transaction.Cycle;
          acc.Chargebacks += transaction.Chargebacks;
          acc.Ethoca += transaction.Ethoca;
          acc.CDRN += transaction.CDRN;
          acc.RDR += transaction.RDR;
          acc.Date = date;
          return acc;
        },
        {
          Total: 0,
          Chargebacks: 0,
          Ethoca: 0,
          CDRN: 0,
          Cycle: 0,
          RDR: 0,
          Date: '',
        },
      );
    });
    setChartCardLabel(dateLabel);
    const result = groupBy(data, (item: IRiskManagement) => item.Cycle);
    const results: IRiskManagement[] = [];

    const transactionList: number[] = [];
    let totalTransaction: number = 0;
    const rdrList: number[] = [];
    let totalRdr = 0;
    const cdrnList: number[] = [];
    let totalCdrn = 0;
    const ethocaList: number[] = [];
    let totalEthoca = 0;
    const chargeBackList: number[] = [];
    const disputeList: number[] = [];
    let totalChargeBack = 0;
    chartData?.forEach((item: IRiskManagement) => {
      transactionList.push(item?.Total);
      rdrList.push(item?.RDR);
      cdrnList.push(item?.CDRN);
      ethocaList.push(item?.Ethoca);
      disputeList.push(item?.CDRN + item?.Ethoca + item?.RDR);
      chargeBackList.push(item?.Chargebacks);
    });

    for (const value in result) {
      let transaction = 0;
      let chargeBacks = 0;
      let ethoca = 0;
      let rdr = 0;
      let cdrn = 0;
      const cycle: number = Number(value);
      result[value].forEach(data => {
        transaction += data.Total;
        chargeBacks += data.Chargebacks;
        ethoca += data.Ethoca;
        cdrn += data.CDRN;
        rdr += data.RDR;
      });
      totalTransaction += transaction;
      totalRdr += rdr;
      totalCdrn += cdrn;
      totalEthoca += ethoca;
      totalChargeBack += chargeBacks;
      const groupedByCycle: IRiskManagement = {
        CDRN: cdrn,
        Chargebacks: chargeBacks,
        Cycle: cycle,
        Ethoca: ethoca,
        RDR: rdr,
        Total: transaction,
      };
      results.push(groupedByCycle);
    }

    setRiskTable(results);
    setTopGraph([
      {
        title: 'Total Approved',
        amount: totalTransaction,
        cardData: transactionList,
      },
      {
        title: 'ChargeBacks Ratio',
        amount: totalChargeBack,
        percentage: () => {
          const total = (totalChargeBack / totalTransaction) * 100;
          if (!Number.isNaN(total)) {
            return total.toFixed(2);
          } else {
            return '0';
          }
        },
        cardData: chargeBackList,
      },
      {
        title: 'Disputes',
        amount: totalCdrn + totalEthoca + totalRdr,
        percentage: () => {
          const total =
            ((totalCdrn + totalEthoca + totalRdr) / totalTransaction) * 100;
          if (!Number.isNaN(total)) {
            return total.toFixed(2);
          } else {
            return '0';
          }
        },
        cardData: disputeList,
      },
      {
        title: 'VISA RDR',
        amount: totalRdr,
        percentage: () => {
          const total = (totalRdr / totalTransaction) * 100;
          if (!Number.isNaN(total)) {
            return total.toFixed(2);
          } else {
            return '0';
          }
        },
        cardData: rdrList,
      },
      {
        title: 'VISA CDRN',
        amount: totalCdrn,
        percentage: () => {
          const total = (totalCdrn / totalTransaction) * 100;
          if (!Number.isNaN(total)) {
            return total.toFixed(2);
          } else {
            return '0';
          }
        },
        cardData: cdrnList,
      },
      {
        title: 'ETHOCA',
        amount: totalEthoca,
        percentage: () => {
          const total = (totalEthoca / totalTransaction) * 100;
          if (!Number.isNaN(total)) {
            return total.toFixed(2);
          } else {
            return '0';
          }
        },
        cardData: ethocaList,
      },
    ]);
  };

  const netSubscriberChartData = (chartData: number[]) => {
    return {
      labels: chartCardLabel,
      datasets: [
        {
          pointBorderColor: 'white',
          pointBackgroundColor: '#F90182',
          borderColor: '#F90182',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 40);
            gradient.addColorStop(0, '#f90182bf');
            gradient.addColorStop(1, '#f9018200');
            return gradient;
          },
          data: chartData,
          total: chartData.reduce((prev, current) => prev + current, 0),
        },
      ],
    };
  };

  const chartCard = () => {
    return (
      <div className="dispute_container">
        {topGraph.map((eachCard, index) => (
          <div className="disputes" key={`card-${index}`}>
            <div className="content">
              <div className="heading">
                <p>{eachCard.title}</p>
              </div>
              <div className="count">
                <span className="total_count">{eachCard.amount}</span>
                {eachCard.percentage && (
                  <span className="total_percent">
                    {eachCard.percentage()}%
                  </span>
                )}
              </div>
            </div>
            <div className="graph">
              <LineChart
                varient="cardChart"
                data={netSubscriberChartData(eachCard.cardData)}
                className="chart"
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="risk_wrapper">
      <div className="risk_wrapper_container">
        <div className="risk-container">
          {chartCard()}
          <div className="risk_management">
            <TableContainer className="Common_Table">
              <Table
                className="risk_management_table"
                aria-labelledby="tableTitle"
                stickyHeader>
                <TableHead className="table_header">
                  <TableRow>
                    <TableCell className="table_header_cell">
                      <div className="flex-row"></div>
                    </TableCell>
                    {headCellList.map(headCell => {
                      if (headCell.hide) {
                        return null;
                      }
                      return (
                        <TableCell
                          className="table_header_cell header_text"
                          key={headCell.label}>
                          {headCell.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className="table_body">
                  {riskTable.length > 0 ? (
                    riskTable.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          className="table_row"
                          role="checkbox"
                          tabIndex={-1}
                          key={index}>
                          <TableCell
                            padding="checkbox"
                            className="table_cell"></TableCell>
                          {headCellList.map(headCell => {
                            if (headCell.hide) {
                              return null;
                            }
                            return (
                              <TableCell
                                className="table_cell"
                                key={headCell.label}
                                component="th"
                                id={headCell.id}
                                scope="row">
                                <>
                                  {headCell?.cellRender
                                    ? headCell.cellRender(row)
                                    : get(row, headCell.id)}
                                </>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <div className="no-data-row">No data found</div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="dashboard_grid">
            <DisputeOverTime
              total={String(topGraph[1]?.amount)}
              totalPercentage={
                topGraph[1]?.percentage && topGraph[1]?.percentage()
              }
              groupedRiskDatas={riskOfChart}
              label={
                sideFormFilter.cardBrand ? sideFormFilter.cardBrand : 'ALL'
              }
            />
            <DisputeReasonCode disputeCodesData={disputeCodesData} />
          </div>
        </div>
        <Loader loading={loading} />
      </div>
      <SideFilter
        sideFormFilter={sideFormFilter}
        filterSubmission={filterSubmission}
      />
    </div>
  );
}

export default index;
