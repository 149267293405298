import React, { useMemo } from 'react';
import Tabs from 'components/common/Tabs';
import Shopify from 'components/store/storeDetails/shopify';
import SubscriptionPlan from 'components/store/storeDetails/SubscriptionPlan';
import UserPermission from 'components/store/storeDetails/userPermission';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Gateway from 'components/gateWay';
import PaymentGateway from 'components/paymentGateway';
import {
  getStoreDetailsPath,
  PageNames,
  pageNames,
  Path,
} from 'router/constant';
import { isFeatureEnabled } from 'utils/helper';

interface ITabsList {
  tabName: string;
  element: JSX.Element;
  showInShopifyApp?: boolean;
  pageName: PageNames;
}

export default function StoreDetails() {
  const [searchParams] = useSearchParams();
  const tabIndex = searchParams.get('tab')
    ? String(JSON.parse(searchParams.get('tab') || '1'))
    : '1';
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const tabsList: ITabsList[] = [
    {
      tabName: 'StoreFront',
      element: <Shopify storeId={params?.storeID ?? ''} />,
      pageName: pageNames.STOREFRONT,
      showInShopifyApp: true,
    },
    {
      tabName: 'Subscription Plan',
      element: <SubscriptionPlan storeId={params?.storeID ?? ''} />,
      pageName: pageNames.SUBSCRIPTION_PLAN,
    },
    {
      tabName: 'User Permission',
      element: <UserPermission storeID={params?.storeID ?? ''} />,
      pageName: pageNames.USER_PERMISSION,
    },
    {
      tabName: 'Payment Gateway',
      element: <Gateway storeID={params?.storeID ?? ''} />,
      pageName: pageNames.PAYMENT_GATEWAY,
    },
    {
      tabName: 'Payment Gateway',
      element: <PaymentGateway storeID={params?.storeID ?? ''} />,
      pageName: pageNames.NEW_PAYMENT_GATEWAY,
    },
  ];

  const handleTabChange = (newValue: string) => {
    if (params?.storeID) {
      navigate(getStoreDetailsPath(params.storeID, Number(newValue)), {
        replace: true,
      });
    }
  };

  const showInShopifyApp = (val: boolean) => {
    return matchPath(Path.SHOPIFY_STORE_DETAILS, location.pathname)
      ? !val
      : false;
  };

  const labelsForTab = useMemo(() => {
    return tabsList.filter(
      val =>
        isFeatureEnabled(val?.pageName) &&
        !showInShopifyApp(val?.showInShopifyApp || false),
    );
  }, [tabsList]);

  return (
    <div className="tab-content">
      <Tabs
        value={tabIndex}
        labels={labelsForTab?.map(value => value.tabName)}
        onChange={handleTabChange}
      />
      <div className="tab-content-wrapper">
        {labelsForTab?.map(
          (val, index) => tabIndex === `${index + 1}` && val.element,
        )}
      </div>
    </div>
  );
}
