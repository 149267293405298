import { useEffect, useState } from 'react';
import 'components/customers/customerDetails/style.scss';
import placeHolderImg from 'assets/icons/Avatar-Placeholder.png';
import { ReactComponent as SubscriptionIcon } from 'assets/icons/subscription-icon.svg';
import { ReactComponent as BillIcon } from 'assets/icons/bill-icon.svg';
import { ReactComponent as LocationIcon } from 'assets/icons//location-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/Icon.svg';
import { ReactComponent as StoreIcon } from 'assets/icons/store-icon.svg';
import { ReactComponent as ShippingIcon } from 'assets/icons/shipping-icon.svg';
import { ReactComponent as MemberIcon } from 'assets/icons/king 1.svg';
import { ReactComponent as TrafficIcon } from 'assets/icons/traffic-icon.svg';
import { ReactComponent as CallIcon } from 'assets/icons/ion_call.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/ic_round-email.svg';
import { ReactComponent as CardIcon } from 'assets/icons/solar_card-bold.svg';
import { ReactComponent as LocationIcon1 } from 'assets/icons/location-icon1.svg';
import SideFilter from 'components/customers/customerDetails/customerDetailsFilter/sideFilter';
import globalCustomersService from 'services/customerService';
import {
  ICustomerDetails,
  ICustomerPaymentDetails,
} from 'interface/customerInterface';
import { useParams } from 'react-router-dom';
import { ClickAwayListener } from '@mui/material';
import { Loader } from 'components/common/loader';
import TabContent from 'components/customers/customerDetails/tabs';
import { formattedDate } from 'utils/helper';
import { SubscriptionStatus } from 'components/SubscriptionManagement/constant';

export interface IStateCustomers {
  CustomerId: string;
  StoreId: string;
}
export default function CustomerDetails() {
  const params = useParams();
  const [customer, setCustomer] = useState<ICustomerDetails | null>(null);
  const [customerPayment, setCustomerPayment] = useState<
    ICustomerPaymentDetails[] | null
  >([]);

  const [loading, setLoading] = useState<number>(0);
  const [isViewClick, setIsViewClick] = useState(false);
  const [addressIndex, setAddressIndex] = useState(0);

  const getCustomerDetails = async () => {
    setLoading(pre => pre + 1);
    const res = await globalCustomersService.getCustomerById({
      customer_id: params?.customer_id,
      store_id: params?.store_id,
    });
    if (res?.data?.Customer) {
      setCustomer(res?.data?.Customer);
    }
    setLoading(pre => pre - 1);
  };
  const getCustomerPaymentDetails = async () => {
    setLoading(pre => pre + 1);
    const res = await globalCustomersService.getCustomerpaymentProfiles({
      customer_id: params?.customer_id,
      store_id: params?.store_id,
    });
    if (res?.data?.payment_profiles)
      setCustomerPayment(res?.data?.payment_profiles);
    setLoading(pre => pre - 1);
  };

  useEffect(() => {
    getCustomerDetails();
    getCustomerPaymentDetails();
  }, []);

  return (
    <div className="customer-details-page">
      <div className="details">
        <div className="profile-info">
          <div className="name-container">
            <div className="image">
              <img src={placeHolderImg} alt="image" />
              <div className="name-wrapper">
                <div className="name-wrap">
                  {customer?.FirstName ||
                    customer?.RecentPaymentProfile?.FirstName}
                </div>
              </div>
            </div>
            <div className="more-info-wrapper">
              <div className="content-wrap">
                <CallIcon />
                {customer?.PhoneNumber ?? '-'}
              </div>
              <div className="content-wrap">
                <EmailIcon />
                {customer?.Email ?? '-'}
              </div>
              {customer?.RecentPaymentProfile?.CCLast4 ? (
                <div className="content-wrap">
                  <CardIcon />
                  {customer?.RecentPaymentProfile?.CCLast4}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="other-info">
          <div className="container-wrap">
            <div className="content-wrap">
              <div className="icon">
                <SubscriptionIcon />
              </div>
              <div className="content-wrapper">
                <div className="title">{'Subscription Status'}</div>
                <div>
                  <span
                    className={`status ${customer?.Subscription?.Status?.toLowerCase()}`}>
                    {customer?.Subscription?.Status === 'unknown'
                      ? '-'
                      : customer?.Subscription?.Status}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {customer?.Subscription?.ID ? (
            <div className="container-wrap">
              <div className="content-wrap">
                <div className="icon">
                  <SuccessIcon />
                </div>
                <div className="content-wrapper">
                  <div className="title">{'Successful Cycless'}</div>
                  <div>
                    <span className="">
                      {customer?.Subscription?.CurrentCycle}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {customer?.Subscription?.ID ? (
            <div className="container-wrap">
              <div className="content-wrap">
                <div className="icon">{<MemberIcon />}</div>
                <div className="content-wrapper">
                  <div className="title">{'Member Since'}</div>
                  <div>
                    <span>
                      {customer?.Subscription?.CreatedAt
                        ? formattedDate(customer?.Subscription?.CreatedAt, true)
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {customer?.Subscription.Status ===
          SubscriptionStatus.subscriptionStatusActive ? (
            <div className="container-wrap">
              <div className="content-wrap">
                <div className="icon">
                  <BillIcon />
                </div>
                <div className="content-wrapper">
                  <div className="title">Next Billing Date</div>
                  <div>
                    <span className="">
                      {customer?.Subscription?.NextBillingDate
                        ? formattedDate(
                            customer?.Subscription?.NextBillingDate,
                            true,
                          )
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="container-wrap">
            <div className="content-wrap">
              <div className="icon">{<StoreIcon />}</div>
              <div className="content-wrapper">
                <div className="title">{'Store'}</div>
                <div>
                  <span>{customer?.Store?.Name}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="container-wrap">
            <div className="content-wrap">
              <div className="icon">{<TrafficIcon />}</div>
              <div className="content-wrapper">
                <div className="title">{'Traffic'}</div>
                <div>
                  <span>
                    {customer?.TrafficChannel ? customer.TrafficChannel : '-'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container-wrap">
            <div className="content-wrap">
              <div className="icon">{<LocationIcon />}</div>
              <div className="content-wrapper">
                <div className="title">
                  {'Billing Address'}
                  {customerPayment && customerPayment?.length > 1 && (
                    <div
                      onClick={() => {
                        setIsViewClick(!isViewClick);
                        setAddressIndex(0);
                      }}>
                      view all
                    </div>
                  )}
                </div>
                <div>
                  <span>{customer?.RecentPaymentProfile?.Address1}</span>
                  <span>{customer?.RecentPaymentProfile?.Address2}</span>
                </div>
                {isViewClick && addressIndex === 0 && (
                  <ClickAwayListener
                    onClickAway={() => {
                      setIsViewClick(false);
                    }}>
                    <div className="address-dropdown">
                      {customerPayment?.map((ele, index) => (
                        <>
                          <div className="more-info" key={index}>
                            <LocationIcon1 /> {ele?.Address1}
                          </div>
                        </>
                      ))}
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            </div>
          </div>
          <div className="container-wrap">
            <div className="content-wrap">
              <div className="icon">{<ShippingIcon />}</div>
              <div className="content-wrapper">
                <div className="title">
                  {'Shipping Address'}

                  {customerPayment && customerPayment?.length > 1 && (
                    <div
                      onClick={() => {
                        setIsViewClick(!isViewClick);
                        setAddressIndex(1);
                      }}>
                      view all
                    </div>
                  )}
                </div>
                <div>
                  <span>{customer?.RecentShippingAddress?.Address1} </span>
                  <span>{customer?.RecentShippingAddress?.Address2}</span>
                </div>
                {isViewClick && addressIndex === 1 && (
                  <ClickAwayListener
                    onClickAway={() => {
                      setIsViewClick(false);
                    }}>
                    <div className="address-dropdown">
                      {customerPayment?.map((ele, index) => (
                        <div className="more-info" key={index}>
                          <LocationIcon1 /> {ele?.Address1}
                        </div>
                      ))}
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="action-icon">
          {/* <ActionIcon /> */}
          <SideFilter
            customer={customer}
            refetch={() => {
              getCustomerDetails();
            }}
          />
        </div>
      </div>

      {
        <TabContent
          state={{
            CustomerId: params?.customer_id ?? '',
            StoreId: params?.store_id ?? '',
          }}
          customer={customer}
        />
      }
      <Loader loading={!!loading} />
    </div>
  );
}
