import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import './style.scss';
import Pagination from 'components/common/pagination';
import { Loader } from 'components/common/loader';
import { useAppSelector } from 'hooks/reduxHooks';
import { IAllUsers } from 'interface/settingsInterface';
import { IInvitation } from 'interface/userInterface';
import { get } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import userService from 'services/userService';
import SearchFilter from 'components/common/searchFilter';
import { iterateHeadCellKeys } from 'utils/helper';
import { useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import AddAdmin from './addAdmin';
import { ReactComponent as RemoveAdmin } from 'assets/icons/remove-admin.svg';
import { PopUpModal, showNotification } from 'shared-components';

export interface HeadCellAdmin {
  id: string;
  label: string;
  cellRender?: (_row: IInvitation) => ReactNode;
  hide?: boolean;
  showSortIcon?: boolean;
  showInSearch?: boolean;
  searchFieldOptions?: { label: string; value: string }[];
  searchFiedtType?: 'input' | 'select';
  inputType?: string;
}
export interface SearchProps {
  [key: string]: string;
}

const rowsPerPage = 25;

const Admins = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearchValue] = useState<SearchProps>({});
  const [limit, setLimit] = useState<number>(
    searchParams.get('Limit')
      ? Number(searchParams.get('Limit')) || rowsPerPage
      : rowsPerPage,
  );
  const { storeIds } = useAppSelector(state => state.storeIds);
  const [totalPage, settotalPage] = useState<number>(0);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState<boolean>(false);
  const [ShowAddEditMode, setShowAddEditMode] = useState<boolean>(false);
  const [removePopup, setRemovePopup] = useState<boolean>(false);
  const [removeUserID, setremoveUserID] = useState<string>('');
  const [onlySuperAdmin, setonlySuperAdmin] = useState<IInvitation[]>([]);
  const [page, setPage] = useState<number>(
    Number(searchParams.get('PageCount') || 1),
  );
  const generateAdminPayload = (isSetSearchParams = true) => {
    const payload: IAllUsers = {
      Descending: true,
      Limit: limit,
      OrderBy: 'CreatedAt',
      Page: page - 1,
      SuperAdmin: true,
    };

    if (Object.values(search).length > 0) {
      payload.SearchFields = search;
      payload.Page = 0;
      setPage(1);
    }
    if (isSetSearchParams) {
      setSearchParams(
        {
          PageCount: String(page),
          Limit: String(limit),
        },
        { replace: true },
      );
    }
    return payload;
  };

  const headCells: readonly HeadCellAdmin[] = [
    {
      id: 'Email',
      label: 'Email',
      showInSearch: true,
    },
    {
      id: 'Name',
      label: 'Name',
      cellRender: (row: IInvitation) => {
        return row?.FirstName + ' ' + row?.LastName;
      },
    },
    {
      id: 'FirstName',
      label: 'First Name',
      showInSearch: true,
      hide: true,
    },
    {
      id: 'LastName',
      label: 'Last Name',
      showInSearch: true,
      hide: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      cellRender: (row: IInvitation) => {
        return (
          <Tooltip title="Revoke Admin" arrow>
            <RemoveAdmin
              className="filter_form_input"
              onClick={handleRemovePopup(row.ID)}
            />
          </Tooltip>
        );
      },
    },
  ];

  const filteredField = iterateHeadCellKeys([...headCells]);

  function handleRemovePopup(userID: string) {
    return () => {
      setRemovePopup(true);
      setremoveUserID(userID);
    };
  }

  const handleConfirm = async () => {
    const userToRemove = onlySuperAdmin?.find(
      user => user?.ID === removeUserID,
    );
    changeToUser(removeUserID, userToRemove?.Email);
    setRemovePopup(false);
  };

  const changeToUser = async (userID: string, email: string | undefined) => {
    setLoading(true);
    const response = await userService.revokeSuperAdmin(userID);

    if (response?.status === 200) {
      showNotification('success', `${email}'s Revoked from Super Admin `);
      getAdmins();
    }

    setLoading(false);
  };

  const getAdmins = async () => {
    setLoading(true);
    const res = await userService.getAllUsers(generateAdminPayload());

    setLoading(false);
    if (res?.status === 200) {
      if (res?.data && res?.data?.Result?.length) {
        settotalPage(res?.data?.TotalCount);
        setIsNextBtnDisabled(res?.data?.Result?.length < limit);
        setonlySuperAdmin(res?.data?.Result);
      } else {
        setonlySuperAdmin([]);
        settotalPage(0);
      }
    } else {
      setonlySuperAdmin([]);
      settotalPage(0);
    }
  };

  useEffect(() => {
    getAdmins();
  }, [page, storeIds, limit, search]);

  return (
    <div className="admin_top_container">
      <div className="admin_search_container">
        <SearchFilter
          filteredField={filteredField}
          setSearchValue={setSearchValue}
          searchValue={search}
        />
      </div>
      <div className="relative h-full overflow-auto">
        <TableContainer className="Common_Table !h-fit max-h-full">
          <Table stickyHeader className="h-full">
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => {
                  if (headCell?.hide) {
                    return null;
                  }
                  return (
                    <TableCell key={index}>
                      <span
                        className={
                          headCell?.label == 'Actions' ? 'text-end block' : ''
                        }>
                        {headCell?.label}
                      </span>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {onlySuperAdmin && onlySuperAdmin.length > 0 ? (
                onlySuperAdmin?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {headCells.map((headCell, index) => {
                        if (headCell?.hide) {
                          return null;
                        }
                        return (
                          <TableCell key={index}>
                            <span
                              className={
                                headCell?.label == 'Actions'
                                  ? 'text-end flex items-center justify-end'
                                  : ''
                              }>
                              {headCell?.cellRender
                                ? headCell?.cellRender(row)
                                : get(row, headCell.id)}
                            </span>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <div className="no-data-row !translate-y-[-50%] !translate-x-[-50%]">
                  No data found
                </div>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={10}>
                  <button
                    className="sticky-button flex items-center"
                    onClick={() => {
                      setShowAddEditMode(true);
                    }}>
                    <AddIcon /> Add User Permissions
                  </button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <Pagination
        perPage={page}
        totalData={totalPage}
        onRowsPerChange={val => {
          setLimit(val);
        }}
        limit={limit}
        currentPage={page}
        isNextDisabled={isNextBtnDisabled}
        onHandleChange={page => {
          setPage(page);
        }}
      />
      {removePopup && (
        <PopUpModal
          open={true}
          handleClose={() => setRemovePopup(false)}
          buttons={[
            {
              buttonType: 'negative',
              buttonLabel: 'Cancel',
              buttonFunction: () => setRemovePopup(false),
            },
            {
              buttonType: 'positive',
              buttonLabel: 'Confirm',
              form: 'AddAdmin',
              type: 'submit',
              disabled: loading,
              buttonFunction: () => handleConfirm(),
              loading,
            },
          ]}
          heading={'Revoke Admin'}>
          <div className="popup-section !w-full">
            <p className="popup-question">{`Are you sure want to revoke permission for this "${onlySuperAdmin?.find(user => user?.ID === removeUserID)?.Email || ''}"?`}</p>
          </div>
        </PopUpModal>
      )}
      {ShowAddEditMode && (
        <AddAdmin
          setShowAddEditMode={setShowAddEditMode}
          onSuccess={() => getAdmins()}
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default Admins;
