import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import {
  IMerchant,
  IMerchantResponse,
  IMerchantUpadate,
} from 'interface/paymentGatewayInterface';
import TextField from 'components/common/textField';
import globalPaymentGateWayService from 'services/paymentGatewayService';
import { showNotification } from 'helper/common/commonFunctions';
import { v4 as uuidv4 } from 'uuid';
dayjs.extend(utc);

interface IProps {
  handleClose: () => void;
  onSuccess: () => void;
  defaultStoreValues: IMerchantResponse;
}

const AddMerchant: FC<IProps> = ({
  handleClose,
  defaultStoreValues,
  onSuccess,
}) => {
  const [loading] = useState(false);
  const { control, handleSubmit } = useForm<IMerchantResponse>({
    defaultValues: defaultStoreValues,
  });
  const addMerchantAccount = async (data: IMerchantResponse) => {
    const payload: IMerchant = {
      AcquiringBankName: data.AcquiringBankName,
      DBAName: data.DBAName,
      GatewayKind: data.GatewayKind,
      MCC: Number(data.MCC),
      MerchantID: data.MerchantID,
      Name: data.Name,
      ProcessorName: data.ProcessorName,
      ServiceProviderName: data.ServiceProviderName,
      GatewayID: uuidv4(),
      IssuedDate: dayjs().utc().tz().toISOString(),
      StoreID: defaultStoreValues?.StoreID,
    };
    const res =
      await globalPaymentGateWayService.createProcessorAccounts(payload);
    if (res.status === 200) {
      showNotification('success', 'Merchant Account Created Successfully');
      onSuccess();
    }
    handleClose();
  };

  const updateMerchantAccount = async (data: IMerchantResponse) => {
    const payload: IMerchantUpadate = {
      ID: data?.ID,
      AcquiringBankName: data.AcquiringBankName,
      DBAName: data.DBAName,
      GatewayID: data.GatewayID,
      GatewayKind: data.GatewayKind,
      IssuedDate: data.IssuedDate,
      MCC: Number(data.MCC),
      MerchantID: data.MerchantID,
      Name: data.Name,
      ProcessorName: data.ProcessorName,
      ServiceProviderName: data.ServiceProviderName,
      StoreID: data.StoreID,
      Version: data.Version,
    };

    const res =
      await globalPaymentGateWayService.updateProcessorAccounts(payload);
    if (res.status === 200) {
      showNotification('success', 'Merchant Account Updated Successfully');
      onSuccess();
    }
    handleClose();
  };

  const handleFormSubmits = (data: IMerchantResponse) => {
    if (data.ID) {
      updateMerchantAccount(data);
    } else {
      addMerchantAccount(data);
    }
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Cancel',
            buttonFunction: () => {
              handleClose();
            },
          },
          {
            buttonType: 'positive',
            buttonLabel: !defaultStoreValues?.ID ? 'Add' : 'Update',
            form: 'AddStoreFront',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={`${!defaultStoreValues?.ID ? 'Add' : 'Update'}`}
        className="flex-container">
        <div className="popup-section">
          <form
            className="grid gap-2.5"
            id="AddStoreFront"
            onSubmit={handleSubmit(handleFormSubmits)}>
            <Controller
              name="Name"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <TextField
                    {...rest}
                    label="Name"
                    placeholder="Enter Name"
                    size="small"
                    errorString={error?.message}
                  />
                );
              }}
            />
            <Controller
              name="MerchantID"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <TextField
                    {...rest}
                    label="Merchant ID"
                    placeholder="Enter Merchant ID"
                    size="small"
                    errorString={error?.message}
                  />
                );
              }}
            />
            <Controller
              name="ProcessorName"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <TextField
                    {...rest}
                    label="Merchant Processor"
                    placeholder="Enter Merchant Processor"
                    size="small"
                    errorString={error?.message}
                  />
                );
              }}
            />
            <Controller
              name="ServiceProviderName"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <TextField
                    {...rest}
                    label="Service Provider"
                    placeholder="Enter Service Provider"
                    size="small"
                    errorString={error?.message}
                  />
                );
              }}
            />
            <Controller
              name="AcquiringBankName"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <TextField
                    {...rest}
                    label="Acquiring Bank"
                    placeholder="Enter Acquiring Bank"
                    size="small"
                    errorString={error?.message}
                  />
                );
              }}
            />

            <Controller
              name="GatewayKind"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <TextField
                    {...rest}
                    label="Gateway Kind"
                    size="small"
                    placeholder="Enter Gateway Kind"
                    errorString={error?.message}
                  />
                );
              }}
            />
            <Controller
              name="DBAName"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <TextField
                    {...rest}
                    label="DBA"
                    placeholder="Enter DBA"
                    size="small"
                    errorString={error?.message}
                  />
                );
              }}
            />
            <Controller
              name="MCC"
              control={control}
              rules={{
                min: {
                  value: 1,
                  message: 'Minimum value should be 1',
                },
                required: 'This field is required',
              }}
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <TextField
                    {...rest}
                    label="MCC"
                    placeholder="Enter MCC"
                    size="small"
                    errorString={error?.message}
                    type="number"
                  />
                );
              }}
            />
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default AddMerchant;
